import React, {useState, useEffect} from 'react';
import {useTranslation} from "react-i18next";
import Subscription from "../../lib/Subscription";
import Redirections from "../../lib/Redirections";
import {useDispatch} from "react-redux";
import {chooseSubscription, setReturnUrl} from "../../redux/actions/checkout";
import CurrencyFormatter from "../../lib/CurrencyFormatter";
import ReactGA from 'react-ga4';

const BikefitPlansForCheckout =
  ({
     returnUrl,
     onlyPayForAccount = false,
     extension = false,
     extendingAccountSubscriptionId = 0
   }) => {
    const [t, i18next] = useTranslation('subscription')

    const [subscriptions, setSubscriptions] = useState([]);
    const dispatch = useDispatch();

    const shouldRedirectDirectly = subscription => {
      return onlyPayForAccount && !subscription.needs_account
    }

    const cookieStorage = {
      getItem: (key) => {
        return localStorage.getItem(`cookie_${key}`)
      },
      setItem: (key, value) => {
        return localStorage.setItem(`cookie_${key}`, value)
      },
    };

    const clickSubscription = subscription => {
      ReactGA.event('add_to_cart', {
        currency: subscription.price.currency,
        value: subscription.price.price / 100,
        items: [{
          item_id: subscription.code,
          item_name: `Product type: ${subscription.type}, code: ${subscription.code}`,
          item_category: subscription.type,
          price: subscription.price.price / 100,
          currency: subscription.price.currency
        }]
      });

      if (shouldRedirectDirectly(subscription)) {
        Redirections.goTo(returnUrl)
      } else {
        dispatch(chooseSubscription(subscription, extension, extendingAccountSubscriptionId))
        dispatch(setReturnUrl(returnUrl))
        Redirections.goTo('/subscription/payment/data')
      }
    }

    const loadSubscriptions = () => {
      Subscription.getSubscriptions('bikefit')
        .then(subscriptions => {
          setSubscriptions(subscriptions.filter(subscription => {
            return extension ? subscription.needs_account : true
          }))
        })
    }

    useEffect(() => {
      cookieStorage.setItem('hasGoneToPaymentPage', 'false');
      loadSubscriptions();
    }, [i18next.language])

    return (<>
      {subscriptions.map((subscription, index) => {
        let buttonText;
        if (shouldRedirectDirectly(subscription)) {
          buttonText = t('Bikefit_StartDirectly');
        } else if (extension) {
          buttonText = t('Bikefit_ExtendAccount');
        } else {
          buttonText = t(subscription.needs_account ? 'Bikefit_PayAccount' : 'Bikefit_PayOnce');
        }

        let price = 0;
        let beforePrice = 0;
        if (subscription.price) {
          price = CurrencyFormatter.format(subscription.price.price / 100, subscription.price.currency);

          if (subscription.price.before_price) {
            beforePrice = CurrencyFormatter.format(subscription.price.before_price / 100, subscription.price.currency);
          }

          if (extension && subscription.price.extension_price) {
            price = CurrencyFormatter.format(subscription.price.extension_price / 100, subscription.price.currency);
            beforePrice = CurrencyFormatter.format(subscription.price.price / 100, subscription.price.currency);
          }
        } else {
          price = 0
        }

        // const points = extractPointsFromSubscription(subscription)

        return (
          <div key={subscription.code} className={'price-card card w-100 p-3 mb-3' + ((!extension && subscription.highlight) ? ' highlighted' : '')}>
            <div className="row ">
              <div className="col-md-3 pl-4 my-auto">
                {/* <h5 className=''>{subscription.lang ? subscription.lang.title : subscription.code}</h5> */}
                <h6><strong>{index === 0 ? t('BIKE_FIT_PLAN_One-Time Bike Fit') : (index === 1 ? t('BIKE_FIT_PLAN_Account For Cyclists') : t('BIKE_FIT_PLAN_Subscription For Professionals'))}</strong></h6>
              </div>
              <div className='col-md-6 my-auto p-md-0'>
                {/* <ul className={(!extension && subscription.highlight) ? 'm-0' : 'm-0 text-muted'}>
                  {points}
                </ul> */}
                {index === 0 && <ul className='m-0 p-md-0'>
                  <li>{t('BIKE_FIT_PLAN_One bike fit report')}</li>
                  <li>{t('BIKE_FIT_PLAN_Based on your current input')}</li>
                </ul>}
                {index === 1 && <ul className='m-0 p-md-0'>
                  <li>{t('BIKE_FIT_PLAN_6 months access to all our services')}</li>
                  <li>{t('BIKE_FIT_PLAN_Unlimited bike fits for one person')}</li>
                  <li>{t('BIKE_FIT_PLAN_Money back guarantee')}</li>
                </ul>}
                {index === 2 && <ul className='m-0 p-md-0'>
                  <li>{t('BIKE_FIT_PLAN_Unlimited bike for one year')}</li>
                  <li>{t('BIKE_FIT_PLAN_Online training')}</li>
                </ul>}
              </div>
              <div className='col-md-3 text-center my-auto'>
                {beforePrice !== 0 && <>
                  <span className="subscription-price"><span className="old-price">{beforePrice}</span></span><br/></>}
                <span className="subscription-price"><span>{price}</span></span><br />
                <button className={'btn btn-light btn-signup' + ((!extension && subscription.highlight) ? ' text-orange' : '')} onClick={() => clickSubscription(subscription)}>{ index===0 ? t('BIKE_FIT_PLAN_Buy') : buttonText }</button>
              </div>
            </div>
          </div>
        )
      })}
    </>)
  }

export default BikefitPlansForCheckout;
