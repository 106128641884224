import React, { useEffect, useState } from "react";
import ReactGA from 'react-ga4';
import { useTranslation } from "react-i18next";
import { AiOutlineUser } from "react-icons/ai";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { checkChoices } from "../../components/Bikefit/lib/bikefit";
import { config, getBaseUrl } from "../../config";
import Auth from "../../lib/Auth";
import Locale from "../../lib/Locale";
import Popup from "../../lib/Popup";
import Redirections from "../../lib/Redirections";
import Subscription from "../../lib/Subscription";
import UrlParser from "../../lib/UrlParser";
import BikeFittingButton from "../../pages/NewLandingPage/components/button/BikeFittingButton";
import { selectBikefitChoices } from "../../redux/selectors/bikefit";
import Login from "../Auth/Login";
import TokenAccess from "../Auth/TokenAccess";
import logo from "./img/best-bike-advice-logo.png";
import logoWebp from "./img/best-bike-advice-logo.webp";
import logoWebp2 from "./img/BBA-logo-white.png";
import "./style.css";
import { selectWhitelabelDomain, selectWhitelabelInfo } from "../../redux/selectors/whitelabel";
import useFeatureFlag from "../../hooks/useFeatureFlag";

const Nav = () => {
  useEffect(() => window.scrollTo(0, 0));
  const choices = useSelector(selectBikefitChoices);
  const [navOpen, setNavOpen] = useState(false);
  const [iHaveSubscription, setIHaveSubscription] = useState("");
  const [iHaveFilledInEverything, setIHaveFilledInEverything] = useState(false);
  const priceFeature = useFeatureFlag('show_price');

  const whiteLabelInfo = useSelector(selectWhitelabelInfo);
  const whiteLabelDomain = useSelector(selectWhitelabelDomain);

  const isBikeFittingQuiz = () => {
    const path = window.location.pathname;
    const splitPath = path.split('/');
    const quizPages = [
      'explanation',
      'bike-type',
      'sitting-position',
      'body-sizes',
      'part-sizes',
      'user-info'
    ];

    if (splitPath[3] === 'bikefit' && quizPages.includes(splitPath[4])) {
      return true;
    }

    return false;
  };

  const enFlag = Locale.getCountry() === 'GB' ? require("./img/gb_flag.jpg") : require("./img/en_flag.jpg");

  useEffect(() => {
    Subscription.checkSubscription("bikefit").then((result) => {
      setIHaveSubscription(result);
    });
  }, []);

  useEffect(() => {
    setIHaveFilledInEverything(checkChoices(choices));
  }, [choices]);

  const [t, i18n] = useTranslation("nav");

  const closeNav = () => setNavOpen(!navOpen);

  const showTokenAccess = () => {
    closeNav();
    Popup.show(<TokenAccess />, true);
  };

  const clickBikefit = () => {
    ReactGA.event({
      action: "click",
      category: "bikefit_nav_start",
      label: "Start bikefit from navigation menu",
      value: 0,
    });

    Redirections.goTo(config.bikefitUrl + "/bikefit/");
    closeNav();
  };

  const changeLanguage = (lng) => {
    closeNav();
    Locale.setLanguage(lng);
  };

  const showLogin = () => {
    closeNav();
    Popup.show(<Login />, true);
  };

  const { path } = UrlParser.parsePath(window.location.href);
  const isBikeFitting = path && path.includes('bikefitting');

  const navItems = [
    {
      name: 'Nav_COST',
      path: '/pricing',
    },
    {
      name: 'Nav_KNOWLEDGE',
      path: '/blog',
    },
    {
      name: 'Nav_OVER_ONS',
      path: '/contact',
    },
  ]

  const newNavItems = priceFeature ? navItems : navItems.filter(item => item.name !== 'Nav_COST')

  const shouldShowTransparentMenu = () => {
    const pathInfo = UrlParser.parsePath(window.location.href)
    let path;

    if (Locale.isLocalizedUrl(window.location.href)) {
      path = '/' + pathInfo.onlyPath.split('/').slice(3).join('/')
    } else {
      path = pathInfo.onlyPath
    }
    return path === '/' || path === '/?'
  }

  return (
    <>
      {
        isBikeFittingQuiz() ? (
          <nav className="simpleNavLogo navbar">
            <a className="navbar-brand" href={Locale.getUrlForCurrentLocale("/")}>
              <picture>
                <source srcSet={(whiteLabelDomain && whiteLabelInfo?.logo_url) ? whiteLabelInfo?.logo_url : logoWebp2} type="image/webp" />
                <img className={`img-responsive ${(whiteLabelDomain) ? 'whitelabel-logo-img' : ''}`} src={(whiteLabelDomain && whiteLabelInfo?.logo_url) ? whiteLabelInfo?.logo_url : logo} alt="Logo BestBikeAdvice" />
              </picture>
            </a>
          </nav>
        ) : (
          <nav
            id="main-navbar"
            className={"navbar navbar-expand-lg navbar-dark " + (shouldShowTransparentMenu() ? (navOpen ? 'navbar-transparent bg-black-mobile' : 'navbar-transparent') : 'bg-dark')}
          >
              <div className={shouldShowTransparentMenu() || isBikeFitting ? "container" : 'w-100 d-flex justify-content-between'}>
              <a className="navbar-brand" href={Locale.getUrlForCurrentLocale("/")}>
                <picture>
                  <source srcSet={logoWebp} type="image/webp" />
                  <img className="img-responsive" src={logo} alt="Logo BestBikeAdvice" />
                </picture>
              </a>
              <button
                className="navbar-toggler"
                type="button"
                onClick={closeNav}
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>

              <div
                className={
                  navOpen ? "collapse navbar-collapse show" : "collapse navbar-collapse"
                }
                id="navbarSupportedContent"
              >
                {
                  isBikeFitting ? (
                      <ul className="navbar-nav ml-auto">
                      {

                        newNavItems.map((item) => (
                          <li className=" mr-xl-4 mr-lg-3 mr-md-2 mr-1 d-flex align-items-center">
                            <Link onClick={closeNav} className="text-white text-l" to={Locale.getUrlForCurrentLocale(item.path)}>
                              {t(item.name)}
                            </Link>
                          </li>
                        ))
                      }
                        <li className="nav-item mr-xl-4 mr-lg-3 mr-md-2 mr-1">
                        <BikeFittingButton buttonColor="#F4DE58" />
                      </li>

                      {Auth.isLoggedIn() && Auth.getAccountType() === "login" && (
                        <li className="nav-item mr-lg-2 mr-xl-3 text-white d-flex align-items-center" style={{ position: 'relative' }}>
                          <button
                            className="nav-link dropdown-toggle userNavButton"
                            data-toggle="dropdown"
                          >
                            <AiOutlineUser style={{ fontSize: '25px' }} />
                          </button>
                          <div className="dropdown-menu">
                            <a
                              className="dropdown-item"
                              onClick={Auth.logout}
                              href={Locale.getUrlForCurrentLocale(getBaseUrl())}
                            >
                              {t("Nav_Logout")}
                            </a>
                          </div>
                        </li>
                      )}
                      {Auth.isLoggedIn() && Auth.getAccountType() !== "login" && (
                        <li className="nav-item mr-lg-2 mr-xl-3 text-white d-flex align-items-center">
                          <button className="nav-link disabled userNavButton" onClick={showTokenAccess} >
                            <AiOutlineUser style={{ fontSize: '25px' }} />
                          </button>
                        </li>
                      )}
                      {!Auth.isLoggedIn() && (
                        <li className="nav-item mr-lg-2 mr-xl-3 text-white d-flex align-items-center">
                          <React.Fragment>
                              <button onClick={showLogin} className="nav-link userNavButton p-0">
                              <AiOutlineUser style={{ fontSize: '25px' }} />
                            </button>
                          </React.Fragment>
                        </li>
                      )}

                      {/* select language */}
                        <li className="nav-item d-flex align-items-center dropdown selected-country m-0">
                          <button className="nav-link dropdown-toggle p-0" data-toggle="dropdown">
                          <img
                            src={Locale.getLanguage() !== "en" ? require("./img/" + Locale.getLanguage() + "_flag.jpg") : enFlag} alt={Locale.getLanguage() === "nl" ? t("Nav_Dutch") : t("Nav_English")} />
                          <i className="fas fa-chevron-down ml-2 align-middle"></i>
                        </button>

                        <div className="dropdown-menu">
                          <button
                            className={
                              i18n.language.substr(0, 2) === "nl"
                                ? "nav-link nl active"
                                : "nav-link nl"
                            }
                            onClick={e => { e.preventDefault(); changeLanguage("nl") }}
                          >
                            <img src={require("./img/nl_flag.jpg")} alt={t("Nav_Dutch")} />

                          </button>
                          <button
                            className={
                              i18n.language.substr(0, 2) === "en"
                                ? "nav-link en active"
                                : "nav-link en"
                            }
                            onClick={() => changeLanguage("en")}
                          >
                            <img src={enFlag} alt={t("Nav_English")} />
                          </button>
                            {
                              whiteLabelDomain &&
                              <button
                                className={
                                  i18n.language.substr(0, 2) === "lt"
                                    ? "nav-link lt active"
                                    : "nav-link lt"
                                }
                                onClick={() => changeLanguage("lt")}
                              >
                                <img src={require("./img/lt_flag.jpg")} alt={t("Nav_Lithuanian")} />
                              </button>
                            }
                        </div>
                      </li>
                    </ul>
                  ) : (
                    <ul className="navbar-nav ml-auto">
                      <li className="nav-item">
                        <Link onClick={closeNav} className="nav-link" to={Locale.getUrlForCurrentLocale('/')}>
                          {t("Nav_Home")}
                        </Link>
                      </li>

                      <li className="nav-item dropdown">
                        <button className="nav-link dropdown-toggle" data-toggle="dropdown">
                          {t("Nav_BikeFit")}
                          <i className="fas fa-chevron-down ml-2"></i>
                        </button>
                        <div className="dropdown-menu">
                          {(localStorage.getItem("last_result_choices") &&
                            localStorage.getItem("last_result_advice") && (
                              <a
                                className="dropdown-item"
                                href={Locale.getUrlForCurrentLocale(config.bikefitUrl + "/bikefit/result-saved")}
                              >
                                {t("Nav_BikefitResult")}
                              </a>
                            )) ||
                            (iHaveSubscription && iHaveFilledInEverything && (
                              <a
                                className="dropdown-item"
                                href={Locale.getUrlForCurrentLocale(config.bikefitUrl + "/bikefit/result-page")}
                              >
                                {t("Nav_BikefitResult")}
                              </a>
                            ))}
                          <a
                            className="dropdown-item"
                            onClick={clickBikefit}
                            href={Locale.getUrlForCurrentLocale(config.bikefitUrl + "/bikefit/")}
                          >
                            {t("Nav_AdviceBikefit")}
                          </a>
                          <a
                            className="dropdown-item"
                            href={Locale.getUrlForCurrentLocale(config.bikefitUrl + "/frame-advice/")}
                          >
                            {t("Nav_AdviceFrame")}
                          </a>
                          <a
                            className="dropdown-item"
                            href={Locale.getUrlForCurrentLocale(config.bikefitUrl + "/cockpit-advice/")}
                          >
                            {t("Nav_AdviceCockpit")}
                          </a>
                        </div>
                      </li>

                      <li className="nav-item dropdown">
                        <button className="nav-link dropdown-toggle" data-toggle="dropdown">
                          {t("Nav_BikeFinder")}
                          <i className="fas fa-chevron-down ml-2"></i>
                        </button>
                        <div className="dropdown-menu">

                          <a
                            className="dropdown-item"
                            href={Locale.getUrlForCurrentLocale(config.raceUrl + "/road")
                            }
                          >
                            {t("Nav_AdviceRace")}
                          </a>
                          <a
                            className="dropdown-item"
                            href={Locale.getUrlForCurrentLocale(config.mtbUrl + "/mtb")}
                          >
                            {t("Nav_AdviceMtb")}
                          </a>
                          <a className="dropdown-item" onClick={clickBikefit} href={Locale.getUrlForCurrentLocale(config.eBikeUrl + '/ebike/')}>
                            {t("Nav_AdviceEbike")}
                          </a>
                          <a className="dropdown-item" onClick={clickBikefit} href={Locale.getUrlForCurrentLocale(config.eBikeUrl + '/compare-bikes/')}>
                            {t("Nav_BikeComparision")}
                          </a>
                        </div>
                      </li>
                      <li className="nav-item mr-2">
                        <Link onClick={closeNav} className="nav-link" to={Locale.getUrlForCurrentLocale("/contact")}>
                          {t("Nav_Contact")}
                        </Link>
                      </li>
                      <li className="nav-item home">
                        {Auth.isLoggedIn() && Auth.getAccountType() === "login" && (
                          <li className="nav-item dropdown">
                            <button
                              className="nav-link dropdown-toggle"
                              data-toggle="dropdown"
                            >
                              {t("Nav_User")}
                            </button>
                            <div className="dropdown-menu">
                              <a
                                className="dropdown-item"
                                onClick={Auth.logout}
                                href={Locale.getUrlForCurrentLocale(getBaseUrl())}
                              >
                                {t("Nav_Logout")}
                              </a>
                            </div>
                          </li>
                        )}
                        {Auth.isLoggedIn() && Auth.getAccountType() !== "login" && (
                          <li className="nav-item">
                            <button className="nav-link disabled" onClick={showTokenAccess}>
                              <i className="fa fa-lock"></i> {t("Nav_TokenAccess")}
                            </button>
                          </li>
                        )}
                        {!Auth.isLoggedIn() && (
                          <React.Fragment>
                            <button onClick={showLogin} className="nav-link">
                              {t("Nav_Login")}
                            </button>
                          </React.Fragment>
                        )}
                      </li>
                      {/* start */}
                      <li className="nav-item dropdown selected-country">
                        <button className="nav-link dropdown-toggle" data-toggle="dropdown">
                          <img src={Locale.getLanguage() !== "en" ? require("./img/" + Locale.getLanguage() + "_flag.jpg") : enFlag} alt={Locale.getLanguage() === "nl" ? t("Nav_Dutch") : t("Nav_English")} />
                          <i className="fas fa-chevron-down ml-2 align-middle"></i>
                        </button>
                        <div className="dropdown-menu">
                          <button
                            className={
                              i18n.language.substr(0, 2) === "nl"
                                ? "nav-link nl active"
                                : "nav-link nl"
                            }
                            onClick={e => { e.preventDefault(); changeLanguage("nl") }}
                          >
                            <img src={require("./img/nl_flag.jpg")} alt={t("Nav_Dutch")} />

                          </button>
                          <button
                            className={
                              i18n.language.substr(0, 2) === "en"
                                ? "nav-link en active"
                                : "nav-link en"
                            }
                            onClick={() => changeLanguage("en")}
                          >
                            <img src={enFlag} alt={t("Nav_English")} />
                          </button>
                              {
                                whiteLabelDomain &&
                                <button
                                  className={
                                    i18n.language.substr(0, 2) === "lt"
                                      ? "nav-link lt active"
                                      : "nav-link lt"
                                  }
                                  onClick={() => changeLanguage("lt")}
                                >
                                  <img src={require("./img/lt_flag.jpg")} alt={t("Nav_Lithuanian")} />
                                </button>
                              }
                        </div>
                      </li>
                      {/* end */}
                    </ul>
                  )
                }
              </div>
            </div>
          </nav >
        )
      }
    </>
  );
};

export default Nav;
