import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./checkoutStyle.css";
import BikefitPlansForCheckout from "./BikefitPlansForCheckout";
import Reviews from "../../lib/Reviews";
import ExpertCardCheckout from "../../pages/Info/infoReview/Reviews/ExpertCardCheckout/ExpertCardCheckout";
import TrustScoreBlack from "../../pages/Homes/BikefitHome/img/Best-bike-advice-beoordelingen.jpg";
import TrustScore from "../../pages/Homes/BikefitHome/img/trustpilot_white_transperant.png";
import ArrowImage from "../../pages/Homes/BikefitHome/img/arrow_image.png";
import Footer from "../Footer";
import Locale from "../../lib/Locale";
import ReactGA from 'react-ga4';
import { Tabs, Tab } from 'react-bootstrap-tabs';
import ReviewMultiCarousel from "../../pages/Homes/components/ReviewMultiCarousel";
import ExpertReviewMultiCarousel from "../../pages/Homes/components/ExpertReviewMultiCarousel";
import CustomerCard from '../Reviews/Customer/CustomerCard';
import ContactForm from '../Contact';
import PreventNavigationDialog from "../PreventNavigationDialog";

/* This one is the actual checkout page */
const BikefitPurchaseOptions = () => {
  const [t, i18n] = useTranslation("subscription");

  const [expertReviews, setExpertReviews] = useState([]);
  const [customerReviews, setCustomerReviews] = useState([]);
  const [returnUrl, setReturnUrl] = useState(
    Locale.getUrlForCurrentLocale("/bikefit/result-page")
  );

  const loadReviews = () => {
    Reviews.getExpertReviews().then((response) => {
      if (response.data.response.data) {
        setExpertReviews(
          response.data.response.data.filter((review) => review.lang)
        );
      }
    });

    Reviews.getCustomerReviews().then((response) => {
      setCustomerReviews(
        response.data.response.data.filter((review) => review.lang)
      );
    });
  };

  useEffect(() => {
    loadReviews();
    processReturnUrlParameter();

    ReactGA.event('view_item_list', { item_list_id: 'bikefit', item_list_name: 'Bikefit' });
  }, [i18n.language]);

  const processReturnUrlParameter = () => {
    const parameterValue = (window.location.search.match(new RegExp(`[?&]returnUrl=([^&]+)`)) || [null, null])[1];

    if (parameterValue) {
      setReturnUrl(
        Locale.getUrlForCurrentLocale(decodeURIComponent(parameterValue))
      );
    }
  };

  return (
    <div className="main bg-dark checkout">
      <PreventNavigationDialog /> 
      <div className="container my-md-4">
        <div className="row">
          <div className="col-lg-5 pt-lg-5 pr-lg-5">
            <h1 className="text-white mt-lg-5"><strong>{t('CHECKOUT_Get Your Personal')}</strong></h1>
            <h1 className="text-warning"><strong>{t('CHECKOUT_Bike Fit Report')}</strong></h1>
            <p className="text-light-custom h5">{t('CHECKOUT_Already more than 10,000 satisfied customers')}</p>
            <hr className="border-secondary" />
            {/* <p className="mt-4"><a href="" className="text-danger">{t('CHECKOUT_Bike Fit Example')} <i className="fa fa-external-link-alt ml-2" aria-hidden="true"></i></a></p> */}
            <div className="row mb-3">
              <div className="col-lg-6">
                <a
                  href="https://nl.trustpilot.com/review/bestbikeadvice.com"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  <img className="trustbox-subscribe mt-2" alt="..." src={TrustScore} />
                </a>
              </div>
              <div className="col-lg-6 text-center d-none d-lg-block">
                <img width={'50%'} alt="..." src={ArrowImage} style={{WebkitTransform: "scaleY(-1) scaleX(-1)", transform: "scaleY(-1) scaleX(-1)", position: "relative", top: "31px"}} />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-12">
                <button
                  onClick={() => document.getElementById("tab-section").scrollIntoView({behavior: "smooth"})}
                  className="btn btn-secondary mx-lg-auto mt-lg-3">{t('Watch example')}</button>
              </div>
            </div>
          </div>
          <div className="col-lg-7">
            <BikefitPlansForCheckout returnUrl={returnUrl} />
          </div>
        </div>
      </div>
      <div className="mt-5 mb-5 text-center">
        <i className="fas fa-chevron-down scroll-down"></i>
      </div>
      <div className="container bg-white tabs-section" id="tab-section">
        <Tabs className="nav-justified">
          <Tab label={t('CHECKOUT_Bike Fit Example')}>
            <div className="row mb-2">
              <div className="col-md-12">
                <h1><strong>{t('CHECKOUT_Bike Fit Example')}</strong></h1>
              </div>
            </div>
            <p className="text-muted">{t('CHECKOUT_This is not your bike fit report')}<br />{t('CHECKOUT_It is an anonymous example so you can get an impression of how your bike fit report will look like')}</p>
            {/* <object data={`${Locale.getLanguage() === "en" ? ENBikeFitExamplePDF : NLBikeFitExamplePDF}#toolbar=0&navpanes=0&scrollbar=0`} type="application/pdf" width="100%" height="900px" frameBorder="0"
              scrolling="auto">
              <p>Your web browser doesn't have a PDF plugin. Instead you can <a href={Locale.getLanguage() === "en" ? ENBikeFitExamplePDF : NLBikeFitExamplePDF}>click here to download the PDF file.</a></p>
            </object> */}
            <div className="row text-center">
              <div className="col-md-12">
                <img className="img-responsive" src={`/img/bikefit/rapport-${Locale.getLanguage()}-1.png`} alt="bikefit example" />
              </div>
              <div className="col-md-12">
                <img className="img-responsive" src={`/img/bikefit/rapport-${Locale.getLanguage()}-2.png`} alt="bikefit example" />
              </div>
              <div className="col-md-12">
                <img className="img-responsive" src={`/img/bikefit/rapport-${Locale.getLanguage()}-3.png`} alt="bikefit example" />
              </div>
              <div className="col-md-12">
                <img className="img-responsive" src={`/img/bikefit/rapport-${Locale.getLanguage()}-4.png`} alt="bikefit example" />
              </div>
            </div>


            {/* <embed
              src={Locale.getLanguage() === "en" ? ENBikeFitExamplePDF : NLBikeFitExamplePDF}
              type="application/pdf"
              frameBorder="0"
              scrolling="auto"
              height="900px"
              width="100%"
          ></embed> */}

          </Tab>
          <Tab label={t('CHECKOUT_Customer Reviews')}>
            <div className="row mb-2">
              <div className="col-md-6">
                <h1><strong>{t('CHECKOUT_Customer Reviews')}</strong></h1>
              </div>
              <div className="col-md-6 text-md-right">
                <a
                  href="https://nl.trustpilot.com/review/bestbikeadvice.com"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  <img className="trustbox-subscribe" alt="..." src={TrustScoreBlack} />
                </a>
              </div>
            </div>
            <ReviewMultiCarousel
              interval={5000}
              reviews={customerReviews}
              component={(review) => <CustomerCard cardClass="w-auto mx-2" review={review} />}
            />
          </Tab>
          <Tab label={t('CHECKOUT_Expert Reviews')}>
            <div className="row mb-2">
              <div className="col-md-12">
                <h1><strong>{t('CHECKOUT_Expert Reviews')}</strong></h1>
              </div>
            </div>
            <ExpertReviewMultiCarousel
              interval={5000}
              reviews={expertReviews}
              component={(props) => <ExpertCardCheckout {...props} />}
            />
          </Tab>
          <Tab label={t('CHECKOUT_Best Value For Money')}>
            <h1 className="mb-5"><strong>{t('CHECKOUT_Reliable Affordable Online')}<br />{t('CHECKOUT_Bike Fit with Gaurantee')}</strong></h1>
            <div className="row">
              <div className="col-md-6">
                <h4><strong>{t('CHECKOUT_Best Bike Advice vs free bike fit tools')}</strong></h4>
                <p className="text-muted">{t('CHECKOUT_You cannot compare our online Bike Fit with any free calculation tools')}</p>
                <div className="text-muted">
                  <ul>
                    <li>{t('CHECKOUT_Your cycling goals')}</li>
                    <li>{t('CHECKOUT_Your cycling experience and level')}</li>
                    <li>{t('CHECKOUT_Your body flexibility')}</li>
                    <li>{t('CHECKOUT_Many body measurements')}</li>
                    <li>{t('CHECKOUT_Your cycling environment')}</li>
                    <li>{t('CHECKOUT_Your bike touch-points (crank, saddle and bar)')}</li>
                    <li>{t('CHECKOUT_Your fork and shock sag (for MTB)')}</li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6">
                <h4><strong>{t('CHECKOUT_Best Bike Advice vs physical bike fitters')}</strong></h4>
                <p className="text-muted">{t('CHECKOUT_The most reliable bike fit is a dynamic bike fit')}</p>
                <p className="text-muted">{t('CHECKOUT_But most people do not have medical issues')}</p>
              </div>
            </div>
          </Tab>
        </Tabs>
      </div>

      <div className="container bg-white">
        <div className="mt-5 tab-content">
          <ContactForm />
        </div>

      </div>


      <Footer />
    </div>
  );
};

export default BikefitPurchaseOptions;
