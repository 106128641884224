import { config } from "../../../config";
import Subscription from "../../../lib/Subscription";
import bikeFitOptions from "../../../bikeFitOptions.json";
import Redirections from "../../../lib/Redirections";

const getResultVars = (choices, tenantId = null) => {
  const { stepA, stepB, stepC, stepD } = choices;

  const bikeTypeOptions = bikeFitOptions.bikeType.data[stepA.bikeType - 1];
  const isRace = bikeTypeOptions.apiValue === "Race";
  const bikeTypeStepName = bikeTypeOptions.substep[0];
  const bikeTypeName =
    bikeFitOptions[bikeTypeStepName].data[stepA[bikeTypeStepName] - 1].apiValue;

  let resultVars = {
    bike_cat: bikeTypeOptions?.apiValue,
    bike_type: bikeTypeName,
    fitness:
      isRace ? bikeFitOptions.sittingPositionRoad.data[stepB.sittingPosition - 1]?.apiValue : bikeFitOptions.sittingPositionMTB.data[stepB.sittingPosition - 1]?.apiValue,
    flexibility:
      bikeFitOptions.flexibility.data[stepB.flexibility - 1]?.apiValue,
    physical:
      stepB.physicalComplaints && (stepB.physicalComplaints[2] === "YES" || stepB.physicalComplaints[3] === "YES")
        ? "Yes"
        : "No",
    comfort_score: stepB.priorities["1"],
    distance_score: stepB.priorities["2"],
    agility_score: stepB.priorities["3"],
    terrain_score: stepB.priorities["4"],
    backsweep: isRace ? null : stepD.partSizes["4"] || null,
    crank: stepD.partSizes["2"],
    road_bar_reach: isRace ? stepD.partSizes["3"] : 0,
    saddle_length: stepD.partSizes["1"],
    travel_front: isRace ? 0 : stepD.suspension["1"],
    travel_rear: isRace ? 0 : stepD.suspension["2"],
    length: stepC.bodyMeasurement,
    arm: stepC.armLength,
    inner_leg_length: stepC.legLength,
    torso_length: stepC.bodyLength,
    torso: stepC.bodyHeight,
    age: 40,
    weight: "90_10",
    upper_leg: stepC.upperThigh,
    lower_leg: stepC.lowerLeg,
    shoe: stepC.shoeSize,
    back_angle: stepB.backAngle,
  };

  if (tenantId) {
    resultVars['tenant_id'] = tenantId;
  }

  return resultVars;
};

function reconstructChoices(result) {
  const choices = {
    stepA: {},
    stepB: {
      physicalComplaints: {},
      priorities: {}
    },
    stepC: {},
    stepD: {
      partSizes: {},
      suspension: {}
    }
  };

  // Step A: Bike Type and Category
  const bikeCat = bikeFitOptions.bikeType.data.find(bt => bt.apiValue === result.bike_cat);
  if (!bikeCat) {
    console.error('Bike type not found:', result.bike_cat);
    return null;
  }
  choices.stepA.bikeType = bikeCat.id;

  const bikeType = bikeFitOptions[bikeCat.substep[0]].data.find(bc => bc.apiValue === result.bike_type);
  if (!bikeType) {
    console.error('Bike category not found:', result.bike_type);
    return null;
  }
  choices.stepA[bikeCat.substep[0]] = bikeType.id;

  // Step B: Fitness and Flexibility
  const fitnessConfig = result.bike_cat === 'Race' ? bikeFitOptions.sittingPositionRoad : bikeFitOptions.sittingPositionMTB;
  const fitnessOption = fitnessConfig.data.find(f => f.apiValue === result.fitness);
  if (!fitnessOption) {
    console.error('Fitness option not found:', result.fitness);
    return null;
  }
  choices.stepB.sittingPosition = fitnessOption.id;

  choices.stepB.backAngle = result.back_angle;

  const flexibilityOption = bikeFitOptions.flexibility.data.find(f => f.apiValue === result.flexibility);
  if (!flexibilityOption) {
    console.error('Flexibility option not found:', result.flexibility);
    return null;
  }
  choices.stepB.flexibility = flexibilityOption.id;

  choices.stepB.physicalComplaints = { 1: "NO", 2: "NO", 3: "NO" };

  // Priorities mapping from result to IDs
  choices.stepB.priorities = {
    '1': result.comfort_score,
    '2': result.distance_score,
    '3': result.agility_score,
    '4': result.terrain_score
  };

  // Part sizes and suspension in Step D
  choices.stepD.partSizes = {
    '1': result.saddle_length,
    '2': result.crank,
    '3': result.road_bar_reach,
    '4': result.backsweep
  };
  choices.stepD.suspension = {
    '1': result.travel_front,
    '2': result.travel_rear
  };

  // Step C: Body Measurements
  choices.stepC.bodyMeasurement = result.length;
  choices.stepC.armLength = result.arm;
  choices.stepC.legLength = result.inner_leg_length;
  choices.stepC.bodyLength = result.torso_length;
  choices.stepC.bodyHeight = result.torso;
  choices.stepC.upperThigh = result.upper_leg;
  choices.stepC.lowerLeg = result.lower_leg;
  choices.stepC.shoeSize = result.shoe;

  return choices;
}


const startBikefit = () => {
  const showLandingPage = () => {
    Redirections.goTo(config.bikefitUrl + "/bikefit/");
  };

  const redirect = () => {
    Redirections.goTo(config.bikefitUrl + "/bikefit/bike-type");
  };

  const startDirectly = window.location.pathname.indexOf("/bikefit") === -1;

  if (startDirectly) {
    Subscription.checkSubscription("bikefit")
      .then((hasSubscription) => {
        if (hasSubscription) {
          redirect();
        } else {
          showLandingPage();
        }
      })
      .catch(showLandingPage);
  } else {
    redirect();
  }
};

const hasSavedBikefit = () => (
  localStorage.getItem("last_result_choices") &&
  localStorage.getItem("last_result_advice")
);

const checkChoices = (choices) => {
  return (
    ((choices.stepB || {}).sittingPosition || false) !== false &&
    ((choices.stepB || {}).priorities["3"] || false) !== false &&
    ((choices.stepC || {}).shoeSize || false) !== false
  );
};

export { startBikefit, checkChoices, getResultVars, reconstructChoices, hasSavedBikefit };
