import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Auth from "../../../lib/Auth";
import DataStore from "../../../lib/DataStore";
import Popup from "../../../lib/Popup";
import ForgotPassword from "../ForgotPassword";
import OverwriteLocalData from "../OverwriteLocalData";
import Register from "../Register";

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
        };
        this.inputEmail = this.inputEmail.bind(this);
        this.inputPassword = this.inputPassword.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                <h3>{Auth.isLoggedIn() ? this.props.t('Login_AlreadyLoggedIn') : this.props.t('Login_Title')}</h3>
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group">
                            <input type="email" name="txtEmail" onChange={this.inputEmail} className="form-control"
                                   placeholder={this.props.t('Login_e-mail')} value={this.state.email}/>
                        </div>
                        <div className="form-group">
                            <input type="password" name="txtPassword" onChange={this.inputPassword}
                                   className="form-control" placeholder={this.props.t('Login_password')}
                                   value={this.state.password}/>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group text-center">
                            <input type="submit" name="btnSubmit" className="btn btn-secondary" disabled={Auth.isLoggedIn()}
                                   value={this.props.t('Login_loginbutton')}/>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="text-center">
                            <button className="btn btn-link" onClick={this.showForgotPassword}>{this.props.t('Login_ForgotPasswordLink')}</button>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="text-center">
                            <button className="btn btn-link" onClick={this.showRegister}>{this.props.t('Login_registerlink')}</button>
                        </div>
                    </div>
                </div>
            </form>
        );
    }

    inputEmail(event) {
        this.setState({email: event.target.value});
    }

    inputPassword(event) {
        this.setState({password: event.target.value});
    }

    showRegister = () => {
        Popup.show(<Register />, true);
    };

    showOverwriteData = (accountData) => {
        Popup.show(<OverwriteLocalData accountData={accountData} />);
    };

    showForgotPassword = () => {
        Popup.show(<ForgotPassword />, true);
    };

    handleSubmit(event) {
        event.preventDefault();
        this.setState({disableButton: true});

        Auth.login(this.state.email, this.state.password)
            .then(() => {
                Auth.getDataSettings()
                    .then((data) => {
                        if (Object.entries(data).length === 0 || ! DataStore.hasValues() || DataStore.isSame(data)) {
                            DataStore.setValue(data);
                            window.location.reload();
                        } else {
                            if (this.props.IgnoreRemoteData) {
                                window.location.reload();
                            } else {
                                this.showOverwriteData(data);
                            }
                        }
                    });
            })
            .catch((code) => {
                switch (code) {
                    case 1002:
                        alert(this.props.t('Login_FailureUnconfirmed'));
                        break;
                    default:
                        alert(this.props.t('Login_Failure'));
                }

                this.setState({disableButton: false});

            });
    }
}

export default withTranslation('login')(Login);
