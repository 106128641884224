import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import Footer from "../../components/Footer";
import { config } from "../../config";
import "./style.css";
import BikefitPlans from "../../components/Subscription/BikefitPlans";
import TermsConditionsLink from "../../components/Policy/TermsConditionsLink";
import PrivacyPolicyLink from "../../components/Policy/PrivacyPolicyLink";
import MultiCarousel from "../Info/infoReview/Reviews/MultiCarousel";
import ExpertCard2 from "../Info/infoReview/Reviews/ExpertCard2/ExpertCard2";
import Reviews from "../../lib/Reviews";
import { Link, Element } from "react-scroll";
import TrustScore from "../Homes/BikefitHome/img/Best-bike-advice-beoordelingen.jpg";
import Locale from "../../lib/Locale";

const Pricing = () => {
  const [t, i18n] = useTranslation("pricing");

  const [expertReviews, setExpertReviews] = useState([]);

  const loadReviews = () => {
    Reviews.getExpertReviews().then((response) => {
      if(response.data.response.data) {
        setExpertReviews(
            response.data.response.data.filter((review) => review.lang)
        );
      }
    });
  };

  useEffect(() => {
    loadReviews();
  }, [i18n.language]);
  return (
    <div className="main">
      <div className="container info-page">
        <div className="row">
          <div className="col-12 trustbox-wrapper">
            <div className="mb-3">
              <h3>{t("Pricing_Title")}</h3>
            </div>
            <div className="mb-3">
              <a
                href="https://nl.trustpilot.com/review/bestbikeadvice.com"
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                <img className="trustbox" alt="..." src={TrustScore} />
              </a>
            </div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-12">
            <p>{t("Pricing_Intro")}</p>
            <p>
              {t("Pricing_Doubt")}

              <Link
                activeClass="active"
                to="expert"
                spy={true}
                smooth={true}
                offset={50}
                duration={500}
                style={{ color: "#f5755d", cursor: "pointer" }}
              >
                {t("Pricing_Expert_Link")}
              </Link>
            </p>
          </div>
        </div>
        <BikefitPlans
          returnUrl={Locale.getUrlForCurrentLocale(config.bikefitUrl + "/bikefit/bike-type")}
          onlyPayForAccount={true}
        />
        <div className="row mt-5 mb-5">
          <div className="col">
            <p>{t("Pricing_PolicyIntro")}</p>
            <p>
              <TermsConditionsLink />
            </p>
            <p>
              <PrivacyPolicyLink />
            </p>
          </div>
        </div>
        <Element name="expert" className="element">
          <div className="row">
            <div className="col-md-12 pt-5 mt-4 pb-4">
              <h2 className="text-center pb-4"> {t("REVIEWS_EXPERT_TITLE")}</h2>
              <div className="d-flex justify-content-center">
                <MultiCarousel
                  reviews={expertReviews}
                  component={(props) => <ExpertCard2 {...props} />}
                />
              </div>
            </div>
          </div>
        </Element>
      </div>

      <Footer />
    </div>
  );
};

export default Pricing;
