import React, {useEffect} from 'react';
import ReactGA from 'react-ga4';
import {config} from "../../config";
import {cookieStorage, updateGoogleConsent} from "./lib/cookieStorage"

const Tracking = (props) => {
  useEffect(() => {
    updateGoogleConsent();
  }, []);

  const injectHotJar = () => {
    (function (h, o, t, j, a, r) {
      h.hj = h.hj || function () {
        (h.hj.q = h.hj.q || []).push(arguments)
      };
      h._hjSettings = {hjid: 1255081, hjsv: 6};
      a = o.getElementsByTagName('head')[0];
      r = o.createElement('script');
      r.async = 1;
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
    })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
  };

  const injectGoogleAds = () => {
    (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({
        'gtm.start': new Date().getTime(), event: 'gtm.js'
      });
      var f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l !== 'dataLayer' ? '&l=' + l : '';
      j.async = true;
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      f.parentNode.insertBefore(j, f);

      function gtag() {
        w[l].push(arguments);
      }

      gtag('set', 'url_passthrough', true);

      gtag('consent', 'default', {
        ad_storage: 'denied',
        analytics_storage: 'denied',
        ad_user_data: 'denied',
        ad_personalization: 'denied',
        wait_for_update: 500
      });
    })(window, document, 'script', 'dataLayer', config.trackGoogleAds);
  };

  const injectFBAds = () => {
    (function (f, b, e, v, n, t, s) {
      if (f.fbq) return;
      n = f.fbq = function () {
        n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = '2.0';
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s)
    })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

    window.fbq('init', config.trackFbAds);
    window.fbq('track', 'PageView');
  };

  const injectMSClarity = () => {
    (function (c, l, a, r, i, t, y) {
      c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
      t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
      y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
    })(window, document, "clarity", "script", config.msClarityProjectId);
  }

  if (config.trackGa !== false) {
    console.log('going to track', props.history.location.pathname + props.history.location.search);
      //ReactGA.initialize(config.trackGa, { 'debug_mode':true });
    ReactGA.initialize(config.trackGa);

    ReactGA.set({'url_passthrough': true});

    ReactGA.gtag('consent', 'default', {
      ad_storage: 'denied',
      analytics_storage: 'denied',
      ad_user_data: 'denied',
      ad_personalization: 'denied',
      wait_for_update: 500
    });

    ReactGA.send({ hitType: "pageview", page: props.history.location.pathname + props.history.location.search });
  }
  
  const statisticsPropertyName = "stats";
  const stats = cookieStorage.getItem(statisticsPropertyName)

  return (<React.Fragment>
    <script>{config.trackGoogleAds !== false && injectGoogleAds()}</script>

    <script>{stats && config.hotJar !== false && injectHotJar()}</script>
    <script>{stats && config.trackFbAds !== false && injectFBAds()}</script>
    <script>{stats && config.msClarityProjectId !== false && injectMSClarity()}</script>
  </React.Fragment>);
};

export default Tracking;
